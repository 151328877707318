<template>
  <header class="header flex w-full justify-between">
    <div class="header__title">
      <NuxtLink to="/">
        <img
          class="header__title-logo w-full"
          src="/images/header/title-logo.svg"
          alt="ミニオン超捜索"
        />
      </NuxtLink>
    </div>
    <div class="header__logo">
      <img
        class="w-full"
        src="/images/header/logo.webp"
        alt="ILLUMINATION'S 怪盗グルーのミニオン超変身"
      />
    </div>
  </header>
</template>

<style scoped lang="scss">
.header {
  padding: calcMinSp(45) calcMinSp(40) 0;
  margin-bottom: calcMaxSp(-167);
}

.header__title {
  width: calcMinSp(214);
}

.header__title-logo {
  filter: drop-shadow(0 0 42.95px rgba(77, 143, 202, 1));
}

.header__logo {
  width: calcMinSp(192);
}
</style>
